import React, {Component} from 'react';
import {graphql, navigate} from 'gatsby';
import {mapStickyBanner, mapGartner} from '../common/utils';
import Layout from '../components/layout';
import MarketoForm from '../components/marketo';
import {IS_MOBILE, DEMO_FORM_IDS, LANG, DEMO_STAGING_TEST} from "../common/consts";
import ComplianceImages from '../components/compliance-images';
import GartnerWidget from '../components/gartner-widget';
import '../assets/styles/pages/_demo.scss';
import '../assets/styles/responsive/pages/_demo-mobile.scss';

const THANK_YOU_MAP = {
    [LANG.ENG]: '/thank-you-demo/',
    [LANG.FR]: '/fr/thank-you-demo/',
    [LANG.DE]: '/de/thank-you-demo/',
    [LANG.JP]: '/jp/thank-you-demo/'
}

class DemoPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true});
        }
    }

    onFormSuccess = () => navigate(THANK_YOU_MAP[this.props.pageContext.lang in THANK_YOU_MAP ? this.props.pageContext.lang : LANG.ENG]);


    render() {
        const {isMobile} = this.state;
        const {data} = this.props;
        const {wpPage} = data;
        const {headlineLoneHeadline, listLoneList, includeGartner, headlineLoneSubheading} = wpPage;
        const stickyBanner = mapStickyBanner(wpPage);
        const gartner = includeGartner ? mapGartner({gartnerType: includeGartner.gartnerType, gartnerInclude: includeGartner.gartnerInclude}) : null;

        return (
            <Layout
                className="demo-page"
                lang={this.props.pageContext.lang}
                formPage={true}
                breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                whiteMenu={false}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                gartner={gartner}
                seoPath={this.props.location.pathname}
                translations={this.props.pageContext.translations}
                wpid={wpPage.databaseId}
                {...{stickyBanner}}>
                <section className="banner-section">
                    <div className="ring"/>
                    <div className="text">
                        {headlineLoneHeadline.loneHeadline && <h1 dangerouslySetInnerHTML={{__html: headlineLoneHeadline.loneHeadline}}/>}
                        {headlineLoneSubheading.loneSubheading && <h2>{headlineLoneSubheading.loneSubheading}</h2>}
                        <div className="form">
                            <MarketoForm
                                formId={DEMO_FORM_IDS[this.props.pageContext.lang]}
                                additionalClass="form-demo"
                                event={{label: this.props.location.pathname}}
                                onFormSuccess={this.onFormSuccess}
                                includeLoader={true}
                                loader={'light'}
                                withClearbit={true}
                            />
                            <script async src="https://marketo.clearbit.com/assets/v1/marketo/forms.js" data-clearbit-publishable-key="pk_442da54a94d2a2ce5bb02532914c65a4"></script>
                            {/*<MarketoForm formId={DEMO_FORM_IDS[this.props.pageContext.lang]} additionalClass="form-demo" event={{label: this.props.location.pathname}}/>*/}
                            <div className="side">
                            {!isMobile && listLoneList.loneList &&
                            <ul className="arrow-list hide-bullets-style">
                                {listLoneList.loneList.map((item, i) => <li className="arrow" key={`list-item-${i}`} dangerouslySetInnerHTML={{__html: item.listItem}}/>)}
                            </ul>
                            }
                                <ComplianceImages/>
                                <GartnerWidget/>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default DemoPage;

export const pageQuery = graphql`
    query demoPageQuery($id: String!){
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headlineLoneHeadline {
                loneHeadline
            }
            headlineLoneSubheading {
                loneSubheading
            }
            listLoneList {
                loneList {
                    listItem
                }
            }
            ...StickyBanner
            ...GartnerStrip
        }
    }
`
