import React from 'react';
import CatoImg from '../cato-image';
import ISO from './ISO.png';
import GDPR from './GDPR.png';
import SOC from './SOC2.png';
import './style.scss';

const ComplianceImages = () => (
    <ul className="compliance-images">
        <li>
            <CatoImg
                img={{publicURL: ISO, alt_text: "ISO Compliant"}}
            />
        </li>
        <li>
            <CatoImg
                img={{publicURL: SOC, alt_text: "SOC Compliant"}}
            />
        </li>
        <li>
            <CatoImg
                img={{publicURL: GDPR, alt_text: "GDPR Compliant"}}
            />
        </li>
    </ul>
);

export default ComplianceImages;